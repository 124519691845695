<script setup lang="ts">
import { ref, watch } from 'vue'
import MultipleSelector from './MultipleSelector.vue'
type Option = {
  label: string
  value: string
}
const props = withDefaults(
  defineProps<{
    endPoint: string
    selectedOptions?: Option[]
    searchParams?: object
    name?: string
    noOptionName?: string
    noOptionValue?: string | null
    placeholder?: string
    inputClass?: string
    searchColumn?: string
    noOptionPlaceholder?: string
  }>(),
  {
    selectedOptions: () => [],
    name: '',
    placeholder: '値を入力',
    inputClass: '',
    searchColumn: 'name_cont',
  }
)
const isNoOption = ref(!!props.noOptionValue)
const noOptionValue = ref(props.noOptionValue)

const selectedOptionsData = ref(props.selectedOptions as Option[])
watch(
  () => props.selectedOptions,
  () => {
    selectedOptionsData.value = props.selectedOptions
  }
)
// eventとoptionを使ってないって言われる 使っとるが？？って感じ
/* eslint-disable no-unused-vars */
const emit = defineEmits<{
  (event: 'selected', option: Option): void
  (event: 'remove', option: Option): void
}>()
/* eslint-enable */

const onSelected = (option: Option) => {
  selectedOptionsData.value.push(option)
  emit('selected', option)
}
const onRemove = (option: Option) => {
  const index = selectedOptionsData.value.indexOf(option)
  if (index >= 0) {
    selectedOptionsData.value.splice(index, 1)
    emit('remove', option)
  }
}
</script>

<template>
  <div class="uk-flex uk-flex-wrap uk-flex-wrap-around">
    <multiple-selector
      :end-point="props.endPoint"
      :placeholder="placeholder"
      :selected-options="selectedOptionsData"
      :search-column="searchColumn"
      :class="inputClass"
      @selected="onSelected"
      @remove="onRemove"
    />
    <input
      v-for="option in selectedOptionsData"
      :key="option.value"
      type="hidden"
      autocomplete="off"
      :name="name"
      :value="option.value"
    />
    <div
      v-if="!isNoOption && selectedOptionsData.length <= 0"
      class="uk-margin-small-top uk-text-meta"
      style="cursor: pointer; display: flex; align-items: center; gap: 4px"
      @click="isNoOption = !isNoOption"
    >
      <span uk-icon="question"></span>
      選択肢がない場合は自由記載
    </div>
    <input
      v-if="isNoOption"
      v-model="noOptionValue"
      class="uk-input uk-margin-top"
      type="text"
      autocomplete="off"
      :placeholder="noOptionPlaceholder"
      :name="noOptionName"
    />
  </div>
</template>
