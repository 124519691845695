<script setup lang="ts">
import { ref } from 'vue'
import SingleSelector from './SingleSelector.vue'
type Option = {
  label: string
  value: string
}
const props = withDefaults(
  defineProps<{
    endPoint: string
    selectedOption?: Option | null
    searchParams?: object
    name?: string
    noOptionName?: string
    noOptionValue?: string | null
    placeholder?: string
    inputClass?: string
    searchColumn?: string
    noOptionPlaceholder?: string
  }>(),
  {
    selectedOption: null,
    name: '',
    placeholder: '値を入力',
    inputClass: '',
    searchColumn: 'name_cont',
  }
)
const isNoOption = ref(!!props.noOptionValue)
const noOptionValue = ref(props.noOptionValue)

const selectedOptionData = ref(props.selectedOption)
// eventとoptionを使ってないって言われる 使っとるが？？って感じ
/* eslint-disable no-unused-vars */
const emit = defineEmits<{
  (event: 'selected', option: Option): void
  (event: 'remove', option: Option): void
}>()
/* eslint-enable */

const onSelected = (option: Option) => {
  selectedOptionData.value = option
  emit('selected', option)
}
const onRemove = (option: Option) => {
  selectedOptionData.value = null
  emit('remove', option)
}
</script>

<template>
  <div class="uk-flex uk-flex-wrap uk-flex-wrap-around">
    <single-selector
      :end-point="props.endPoint"
      :placeholder="placeholder"
      :selected-option="selectedOptionData"
      :search-column="searchColumn"
      :class="inputClass"
      @selected="onSelected"
      @remove="onRemove"
    />
    <input
      type="hidden"
      autocomplete="off"
      :name="name"
      :value="selectedOptionData?.value"
    />
    <div
      v-if="!isNoOption && !selectedOptionData?.value"
      class="uk-margin-small-top uk-text-meta"
      style="cursor: pointer; display: flex; align-items: center; gap: 4px"
      @click="isNoOption = !isNoOption"
    >
      <span uk-icon="question"></span>
      選択肢がない場合は自由記載
    </div>
    <input
      v-if="isNoOption"
      v-model="noOptionValue"
      class="uk-input uk-margin-top"
      type="text"
      autocomplete="off"
      :placeholder="noOptionPlaceholder"
      :name="noOptionName"
    />
  </div>
</template>
