// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')
console.log('FORM PORTAL')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log(
  'Visit the guide for more information: ',
  'https://vite-ruby.netlify.app/guide/rails'
)

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
import '../../assets/stylesheets/application.scss'

import 'uikit'
import UIkit from 'uikit/dist/js/uikit'
import Icons from 'uikit/dist/js/uikit-icons'

import { createApp } from 'vue/dist/vue.esm-bundler.js'
import { createPinia } from 'pinia'
import vClickOutside from 'click-outside-vue3'
import SingleInputSelectorWithTextField from '../components/FormPortal/SingleInputSelectorWithTextField.vue'
import ReportFormSelector from '../components/FormPortal/ReportFormSelector.vue'
document.addEventListener('DOMContentLoaded', () => {
  const app = createApp({
    components: {
      SingleInputSelectorWithTextField,
      ReportFormSelector,
    },
  })
  app.use(createPinia())
  app.use(vClickOutside)
  app.mount('#app')
})
// UIKit Plugin loads
UIkit.use(Icons)

import mrujs from 'mrujs'

mrujs.start()
