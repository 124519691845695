<script setup lang="ts">
import Multiselect from 'vue-multiselect'
import axios from 'axios'
import qs from 'qs'
import { onMounted, reactive, ref, watch, toRefs } from 'vue'
type Option = {
  label: string
  value: string
}
const props = withDefaults(
  defineProps<{
    endPoint: string
    placeholder?: string
    selectedOption?: Option | null
    isGrouped?: boolean
    searchParams?: object
    searchColumn: string
  }>(),
  {
    placeholder: 'Select one',
    selectedOption: null,
    isGrouped: false,
    searchparams: {},
  }
)
const selected = ref(props.selectedOption as Option | null)
const { selectedOption } = toRefs(props)
const state = reactive({ options: [] as Option[] })

watch(selectedOption, () => {
  selected.value = selectedOption.value
})

const isLoading = ref(false)
const _groupValues = () => {
  if (props.isGrouped) {
    return 'groupElement'
  } else {
    return null
  }
}
const _groupLabel = () => {
  if (props.isGrouped) {
    return 'groupLabel'
  } else {
    return null
  }
}
const groupValues = _groupValues()
const groupLabel = _groupLabel()
const fetchOptions = (q = '') => {
  isLoading.value = true
  const params = {
    q: Object.assign({ [props.searchColumn]: q }, props.searchParams),
  }
  axios
    .get(props.endPoint, {
      params: params,
      paramsSerializer(params) {
        return qs.stringify(params)
      },
    })
    .then((response) => {
      const options = response.data
      if (selected.value?.value) {
        options.unshift(selected.value)
      }
      state.options = options
      isLoading.value = false
    })
}
onMounted(() => {
  fetchOptions()
})
const onSearchChange = (q: string) => {
  fetchOptions(q)
}
// eventとoptionを使ってないって言われる 使っとるが？？って感じ
/* eslint-disable no-unused-vars */
const emit = defineEmits<{
  (event: 'selected', option: Option): void
  (event: 'remove', option: Option): void
}>()
/* eslint-enable */

const onSelect = (option: Option) => {
  emit('selected', option)
}
const onRemove = (option: Option) => {
  emit('remove', option)
}
</script>

<template>
  <multiselect
    v-model="selected"
    track-by="value"
    label="label"
    :loading="isLoading"
    :placeholder="placeholder"
    :options="state.options"
    :group-values="groupValues"
    :group-label="groupLabel"
    :group-select="false"
    :internal-search="false"
    select-label="Select"
    deselect-label="Remove"
    :allow-empty="true"
    @search-change="onSearchChange"
    @select="onSelect"
    @remove="onRemove"
  />
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
