<script setup lang="ts">
import Multiselect from 'vue-multiselect'
import axios from 'axios'
import qs from 'qs'
import { onMounted, reactive, ref, watch } from 'vue'
type Option = {
  label: string
  value: string
}
const props = defineProps({
  endPoint: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: 'Select one',
  },
  selectedOptions: {
    type: Array<Option>,
    default: [],
  },
  isGrouped: {
    type: Boolean,
    default: false,
  },
  searchParams: {
    type: Object,
    default: () => ({}),
  },
  searchColumn: {
    type: String,
    required: true,
  },
})
const selected = ref(props.selectedOptions as Option[])
const state = reactive({ options: [] as Option[] })
watch(
  () => props.selectedOptions,
  () => {
    selected.value = props.selectedOptions
  }
)
const isLoading = ref(false)
const _groupValues = () => {
  if (props.isGrouped) {
    return 'groupElement'
  } else {
    return null
  }
}
const _groupLabel = () => {
  if (props.isGrouped) {
    return 'groupLabel'
  } else {
    return null
  }
}
const groupValues = _groupValues()
const groupLabel = _groupLabel()
const fetchOptions = (q = '') => {
  isLoading.value = true
  const params = {
    q: Object.assign({ [props.searchColumn]: q }, props.searchParams),
  }
  axios
    .get(props.endPoint, {
      params: params,
      paramsSerializer(params) {
        return qs.stringify(params)
      },
    })
    .then((response) => {
      state.options = response.data
    })
  isLoading.value = false
}
watch(props.selectedOptions, () => {
  selected.value = props.selectedOptions
})
onMounted(() => {
  fetchOptions()
})
const onSearchChange = (q: string) => {
  fetchOptions(q)
}
// eventとoptionを使ってないって言われる 使っとるが？？って感じ
/* eslint-disable no-unused-vars */
const emit = defineEmits<{
  (event: 'selected', option: Option): void
  (event: 'remove', option: Option): void
}>()
/* eslint-enable */

const onSelect = (option: Option) => {
  emit('selected', option)
}
const onRemove = (option: Option) => {
  emit('remove', option)
}
</script>

<template>
  <multiselect
    v-model="selected"
    track-by="value"
    label="label"
    :multiple="true"
    :searchable="true"
    :loading="isLoading"
    :placeholder="placeholder"
    :options="state.options"
    :group-values="groupValues"
    :group-label="groupLabel"
    :group-select="false"
    select-label="Select"
    deselect-label="Remove"
    @search-change="onSearchChange"
    @select="onSelect"
    @remove="onRemove"
  >
    <template #tag="{ option, remove }">
      <span>
        <span
          :class="option.labelClass"
          class="uk-label uk-border-rounded uk-margin-small-bottom uk-margin-small-right"
        >
          {{ option.label }}
          <a class="uk-text-lowercase uk-light" @click="remove(option)"> ✖️ </a>
        </span>
      </span>
    </template>
    <template #clear="{ props, clearAll }">
      <div
        v-if="selected.length"
        class="multiselect__clear"
        @mousedown.prevent.stop="clearAll(props.search)"
      />
    </template>
    <template #noResult>
      <span>検索結果はありませんでした。別のワードで検索してください。</span>
    </template>
  </multiselect>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
