<script setup lang="ts">
import { computed, ref } from 'vue'
import SingleInputSelectorWithTextField from './SingleInputSelectorWithTextField.vue'
import MultipleInputSelectorWithTextForm from './MultipleInputSelectorWithTextForm.vue'
type Option = {
  label: string
  value: string
}
const props = defineProps<{
  storeId?: number | null
  clinicName?: string | null
  treatmentName?: string | null
  doctorName?: string | null
  selectedStoreOption?: Option | null
  selectedMenuOptions: Option[]
  selectedDoctorOption?: Option | null
}>()

const storeIdRef = ref(props.storeId)
const selectedStoreOptionRef = ref(props.selectedStoreOption)
const selectedMenuOptionsRef = ref(props.selectedMenuOptions)
const selectedDoctorOptionRef = ref(props.selectedDoctorOption)
const onSelectedStore = (option: Option) => {
  storeIdRef.value = Number(option.value)
  selectedMenuOptionsRef.value = []
}
const onRemoveStore = () => {
  storeIdRef.value = null
  selectedMenuOptionsRef.value = []
}

const menuEndPoints = computed(() => {
  return storeIdRef.value
    ? `/api/v1/menus/select_options?store_id=${storeIdRef.value}`
    : '/api/v1/menus/select_options'
})
</script>

<template>
  <div class="uk-margin uk-margin-medium-bottom">
    <label class="uk-form-label"
      >クリニック名(<span class="uk-text-danger">必須</span>)</label
    >
    <label class="uk-form-label"
      >webサイトで記載されている正式名称で記載をお願いします。<br />
      例)湘南美容外科クリニック 渋谷院</label
    >

    <div class="uk-form-controls">
      <SingleInputSelectorWithTextField
        name="report_form[store_id]"
        no-option-name="report_form[clinic_name]"
        end-point="/api/v1/stores/select_options"
        placeholder="タップして検索"
        no-option-placeholder="例)湘南美容外科クリニック 渋谷院"
        :selected-option="selectedStoreOptionRef"
        :no-option-value="clinicName"
        @selected="onSelectedStore"
        @remove="onRemoveStore"
      />
    </div>
  </div>
  <div class="uk-margin uk-margin-medium-bottom">
    <label class="uk-form-label"
      >施術名(<span class="uk-text-danger">必須</span>)</label
    >
    <div class="uk-form-controls">
      <MultipleInputSelectorWithTextForm
        :key="storeIdRef || '0'"
        name="report_form[menu_ids][]"
        no-option-name="report_form[treatment_name]"
        :end-point="menuEndPoints"
        placeholder="タップして検索"
        no-option-placeholder="例)二重埋没法"
        :selected-options="selectedMenuOptionsRef"
        :no-option-value="treatmentName"
      />
    </div>
  </div>
  <div class="uk-margin uk-margin-medium-bottom">
    <label class="uk-form-label">施術された先生のお名前(任意)</label>
    <div class="uk-form-controls">
      <SingleInputSelectorWithTextField
        name="report_form[doctor_id]"
        no-option-name="report_form[doctor_name]"
        end-point="/api/v1/doctors/select_options"
        placeholder="タップして検索"
        no-option-placeholder="例)山田先生"
        :selected-option="selectedDoctorOptionRef"
        :no-option-value="doctorName"
      />
    </div>
  </div>
</template>
